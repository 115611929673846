<template>
  <div class="tab-content" id="PTtab-10" :key="'PTtab-10'" >
    <div>
      <div class="pagenamPT">
        <h3 class="pagename2 w100w">접속중인회원</h3>
      </div>
      <div class="sch_Normal">
        <div class="sch_Btm">
          <div class="searchPTwrap">
            <div class="searchPT w30p">
              <div class="datesearchPT flex-c gap-2">
                <h4>구분</h4>
                <select class="h36px">
                  <option value="memId" selected>아이디</option>
                </select>
              </div>
            </div>
            <div class="searchPT">
              <input type="text" :placeholder="$t('front.search.emptySearch')" v-model="searchMemId" class="h36px"/>
              <a @click="getLoginMember(1)" class="sch_Icon">
                <img src="@/assets/img/search.png" alt=""/>
              </a>
            </div>
          </div>
          <div class="searchPTwrap">
            <div class="datesearchPT flex-c gap-2 w50p">
              <h4>정렬</h4>
              <select class="h36px" v-model="sort">
                <option value="all">전체</option>
                <option value="cashAmt_-1">보유머니 많은 순</option>
                <option value="cashAmt_1">보유머니 적은 순</option>
                <option value="pointAmt_-1">보유포인트 많은 순</option>
                <option value="pointAmt_1">보유포인트 적은 순</option>
                <option value="cashIn_-1">입금금액 많은 순</option>
                <option value="cashIn_1">입금금액 적은 순</option>
                <option value="cashOut_-1">출금금액 많은 순</option>
                <option value="cashOut_1">출금금액 적은 순</option>
                <option value="betAmt_-1">베팅금 많은 순</option>
                <option value="betAmt_1">베팅금 적은 순</option>
                <option value="winAmt_-1">당첨금 많은 순</option>
                <option value="winAmt_1">당첨금 적은 순</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="strTablescr">
         <div class="strTablePC">
            <table class="strTablePT">
              <colgroup>
                <col width="9%">
              </colgroup>
              <thead>
                <tr>
                  <th>아이디</th>
                  <th>닉네임</th>
                  <th>롤링/루징<br>(카지노)</th>
                  <th>보유머니</th>
                  <th>보유포인트</th>
                  <th>입/출금액</th>
                  <th>입출손익</th>
                  <th>베팅/당첨금액</th>
                  <th>베팅손익</th>
                  <th>최근 실행 게임<br>게임명</th>
                  <th>이동</th>
                  <th>접속시간<br>접속IP</th>
                </tr>
                <!-- <tr>
                  <th>아이디</th>
                  <th>닉네임</th>
                  <th>보유금</th>
                  <th>보유포인트</th>
                  <th>로그인 일시</th>
                  <th>머니 관리</th>
                </tr> -->
              </thead>
              <tbody>
                <template v-if="loginMemList.length > 0">
                  <template v-for="(item, index) in loginMemList" :key="item.memId">
                    <tr>
                      <td>{{item.memId}}</td>
                      <td class="nick">{{item.memNick}}</td>
                      <td>
                        <div class="btn-input-wrap">
                          <span>{{item.pointRate}}%/{{item.loseRate}}%</span>
                          <a class="plus-btn" @click="onOpenRate(item)"></a>
                        </div>
                      </td>
                      <td>
                        <div class="btn-input-wrap">
                          <span class="mw-73">{{thousand(item.cashAmt)}}</span>
                          <a class="inout-btn" @click="onOpenPay(item, index)">지급회수</a>
                        </div>
                      </td>
                      <td>
                        <div class="btn-input-wrap">
                          <span class="mw-73">{{thousand(item.pointAmt)}}</span>
<!--                          <a class="inout-btn" @click="onOpenPoint(item, index)">지급회수</a>-->
                        </div>
                      </td>
                      <td>
                        <div class="twoline-wrap">
                          <span><em>{{ $t('front.common.deposit') }}</em>{{thousand(item.cashIn)}}</span>
                          <span><em>{{ $t('front.common.withdrawal') }}</em>{{thousand(item.cashOut)}}</span>
                        </div>
                      </td>
                      <td>
                        <div :class="{ 'rdc' : Number(item.cashOut) - Number(item.cashIn) > 0, 'blc' : Number(item.cashOut) - Number(item.cashIn) < 0}">
                          {{thousand(Number(item.cashOut) - Number(item.cashIn))}}
                        </div>
                      </td>
                      <td>
                        <div class="twoline-wrap">
                          <span><em>{{ $t('front.bettingStatus.BET') }}</em>{{thousand(item.betAmt)}}</span>
                          <span><em>{{ $t('front.common.winning') }}</em>{{thousand(item.winAmt)}}</span>
                        </div>
                      </td>
                      <td>
                        <div :class="{ 'rdc' : Number(item.winAmt) - Number(item.betAmt) > 0, 'blc' : Number(item.winAmt) - Number(item.betAmt) < 0}">
                          {{thousand(Number(item.winAmt) - Number(item.betAmt))}}
                        </div>
                      </td>
                      <td>
                        <div class="twoline-wrap f-center">
                          <span>{{item.betData}}</span>
                        </div>
                      </td>
                      <td>
                        <div class="btn-input-wrap">
                          <a class="inout-btn color-btn-y" @click="parentLinkMove('/bettingList', item.memId)">베팅내역</a>
                        </div>
                      </td>
                      <td>{{dateFormatAll(item.loginDt)}}<br />{{ item.loginIP }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
         </div>
        <div class="strTableM">
          <div class="strTablePTM">
            <template v-if="loginMemList.length > 0">
              <template v-for="(item, index) in loginMemList" :key="item.memId">
                <ul>
                  <li>
                    <em>아이디</em>
                    <div><span>{{item.memId}}</span></div>
                  </li>
                  <li>
                    <em>롤링/루징<br>(카지노)</em>
                    <div class="m-table-r">
                      <span class="rate-font">{{item.pointRate}}%/{{item.loseRate}}%</span>
                      <a class="plus-btn" @click="onOpenRate(item)"></a>
                    </div>
                  </li>
                  <li>
                    <em>닉네임</em>
                    <div><span class="nick">{{item.memNick}}</span></div>
                  </li>
                  <li>
                    <em>최근 실행 게임<br>게임 명</em>
                    <div><span class="text-break">{{item.betData}}</span></div>
                  </li>
                  <li>
                    <em>보유머니</em>
                    <div class="m-table">
                      <a class="inout-btn" @click="onOpenPay(item, index)">지급/회수</a>
                      <span>{{thousand(item.cashAmt)}}</span>
                    </div>
                  </li>
                  <li>
                    <em>이동</em>
                    <div class="m-table">
                      <a class="inout-btn color-btn-y" @click="parentLinkMove('/bettingList', item.memId)">베팅내역</a>
                    </div>
                  </li>
                  <li>
                    <em>보유포인트</em>
                    <div class="m-table">
<!--                      <a class="inout-btn" @click="onOpenPoint(item, index)">지급/회수</a>-->
                      <span>{{thousand(item.pointAmt)}}</span>
                    </div>
                  </li>
                  <li>
                    <em>로그인 일시</em>
                    <div>
                      <span>{{dateFormatAll(item.loginDt)}}<br />{{ item.loginIP }}</span>
                    </div>
                  </li>
                  <li>
                    <em>입/출금액</em>
                    <div><!--column-->
                      <ul class="m-table-c">
                        <li>
                          <span>{{ $t('front.common.deposit') }}</span>
                          <span>{{thousand(item.cashIn)}}</span>
                        </li>
                        <li>
                          <span>{{ $t('front.common.withdrawal') }}</span>
                          <span>{{thousand(item.cashOut)}}</span>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <em>베팅/당첨금액</em>
                    <div><!--column-->
                      <ul class="m-table-c">
                        <li>
                          <span>{{ $t('front.bettingStatus.BET') }}</span>
                          <span>{{thousand(item.betAmt)}}</span>
                        </li>
                        <li>
                          <span>{{ $t('front.common.winning') }}</span>
                          <span>{{thousand(item.winAmt)}}</span>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <em>입출손익</em>
                    <div>
                      <span :class="{ 'rdc' : Number(item.cashOut) - Number(item.cashIn) > 0, 'blc' : Number(item.cashOut) - Number(item.cashIn) < 0}">{{thousand(Number(item.cashOut) - Number(item.cashIn))}}</span> <!--마이너스 파란색 플러스 빨간색-->
                    </div>
                  </li>
                  <li>
                    <em>베팅손익</em>
                    <div>
                      <span :class="{ 'rdc' : Number(item.winAmt) - Number(item.betAmt) > 0, 'blc' : Number(item.winAmt) - Number(item.betAmt) < 0}">{{thousand(Number(item.winAmt) - Number(item.betAmt))}}</span> <!--마이너스 파란색 플러스 빨간색-->
                    </div>
                  </li>
                </ul>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div v-if="openMoneyMovePopup" class="moneyg">
      <a class="close" @click="onCloseMoneyPopup(moveTarget)"><img src="@/assets/img/icon_cancelB.svg" /></a>
      <h2>보유머니: <span>{{thousand(moveTarget.cashAmt)}}</span></h2>
      <input type="text" pattern="[0-9.,]+" :placeholder="$t('front.give.moneyInput')" v-model="moveTarget.inputCashAmt" @keyup="updateCashAmt(item, $event.target.value)" />
      <div class="mbtn">
        <a @click="setMoney(moveTarget, 10000)">1{{$t('front.cash.manwon')}}</a>
        <a @click="setMoney(moveTarget, 30000)">3{{$t('front.cash.manwon')}}</a>
        <a @click="setMoney(moveTarget, 50000)">5{{$t('front.cash.manwon')}}</a>
        <a @click="setMoney(moveTarget, 100000)">10{{$t('front.cash.manwon')}}</a>
      </div>
      <div class="mbtn">
        <a @click="setMoney(moveTarget, 30000)">3{{$t('front.cash.manwon')}}</a>
        <a @click="setMoney(moveTarget, 50000)">5{{$t('front.cash.manwon')}}</a>
        <a @click="setMoney(moveTarget, 100000)">10{{$t('front.cash.manwon')}}</a>
        <a @click="setMoney(moveTarget, 0)" style="background: #730000;">{{$t('front.cash.correct')}}</a>
      </div>
      <div class="submit">
        <a class="bgb btn2" @click="memCash('-3', moveTarget)">{{$t('front.give.give')}}</a>
        <a class="bgr btn2" @click="memCash('3', moveTarget)">{{$t('front.give.back')}}</a>
      </div>
    </div>
  </div>
  <transition name="fade">
    <Paymodal v-if="PayMopen" :selectMem="selectMem" :selectIdx="selectIdx" @close="onClosePay" />
  </transition>
  <transition name="fade">
    <PayPointmodal v-if="PayPMopen" :selectMem="selectMem" :selectIdx="selectIdx" @close="onClosePoint"/>
  </transition>
  <transition name="fade">
    <RateTable v-if="RateOpen" :selectMem="selectMem" @close="onCloseRate"/>
  </transition>
</template>

<script>
import Paymodal from '@/components/common/Pay.vue'
import PayPointmodal from '@/components/common/PayPoint.vue'
import RateTable from '@/components/common/RateTable.vue'
import {
  getRetailMyMemList, partnerLevels, partnerMyMemIds, getRetailMyLoginList
} from '@/api/retail'
import { thousand } from '@/libs/utils'
import { memCashInOut } from '@/api/give'
import { mapState } from 'vuex'
import { PARTNER_LEVEL_NAME } from '@/libs/constants'

export default {
  name: 'onlineMemberList',
  components: { Paymodal, PayPointmodal, RateTable },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ]),
    partnerLevelName () {
      console.log(this.item.partnerLevel)
      return PARTNER_LEVEL_NAME[this.item.partnerLevel]
    },
    formattedCashAmt () {
      if (!this.cashAmt) return ''
      return this.cashAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  data () {
    return {
      searchType: 'N',
      searchPartnerLevel: '',
      partnerLevelList: [],
      partnerList: [],
      partnerLevelObject: null,
      summaryNew: null,
      searchMemId: '',
      cashAmt: '',
      selectIdx: '',
      selectMem: {
        cashAmt: ''
      },
      list: [],
      isProcessing: false,
      moveTarget: null,
      loginMemList: [],
      openMoneyMovePopup: false,
      PayMopen: false,
      PayPMopen: false,
      RateOpen: false,
      sort: 'all'
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    this.getPartnerLevels()
    await this.getLoginMember()
    this.emitter.emit('Loading', false)
  },
  watch: {
    sort () {
      if (this.sort !== 'all') {
        console.log(this.sort)
        const key = this.sort.split('_')[0]
        const order = this.sort.split('_')[1]

        this.loginMemList.sort((a, b) => {
          let aValue = a[key]
          let bValue = b[key]

          if (aValue.indexOf(',') > -1) {
            aValue = aValue.replace(/,/, '')
          }

          if (bValue.indexOf(',') > -1) {
            bValue = bValue.replace(/,/, '')
          }

          aValue = Number(aValue)
          bValue = Number(bValue)

          return aValue > bValue ? Number(order) : 1 - Number(order) - 1
        })
      } else {
        this.getLoginMember()
      }
    },
    selectPartnerCode () {
      if (this.selectPartnerCode) {
        this.selectMem.cashAmt = ''
        this.getMyMemIds()
      }
    },
    selectMem () {
      if (this.selectMem) {
        this.selectMem.cashAmt = thousand(this.selectMem.cashAmt)
      }
    }
  },
  methods: {
    onOpenRate (selectMem) {
      this.selectMem = {
        ...selectMem
      }
      this.RateOpen = true
    },
    onCloseRate () {
      this.RateOpen = false
      this.selectMem = null
    },
    onClosePay (newCash, idx) {
      if (newCash !== null && newCash !== undefined) {
        this.loginMemList[idx].cashAmt = newCash
      }
      this.PayMopen = false
      this.selectMem = null
    },
    onClosePoint (newPoint, idx) {
      if (newPoint !== null) {
        this.loginMemList[idx].pointAmt = newPoint
      }
      this.PayPMopen = false
      this.selectMem = null
    },
    onOpenPay (selectMem, selectIdx) {
      this.selectIdx = selectIdx
      this.selectMem = {
        ...selectMem
      }
      console.log(selectMem)
      this.PayMopen = true
    },
    onOpenPoint (selectMem, selectIdx) {
      this.selectIdx = selectIdx
      this.selectMem = {
        ...selectMem
      }
      console.log(selectMem)
      this.PayPMopen = true
    },
    thousand,
    parentLinkMove (linkTarget, memId) {
      const pathname = location.pathname.split('/')[1]
      console.log(`${pathname}${linkTarget}`)
      window.open(`/${pathname}${linkTarget}?id=${memId}`, '_blank')
    },
    async getLoginMember () {
      const params = {
        memId: this.searchMemId
      }
      const res = await getRetailMyLoginList(params)
      console.log('getRetailMyLoginList : ', res.data)
      const result = res.data
      if (result.resultCode === '0') {
        this.loginMemList = result.data.list
      }
    },
    onOpenMoneyPopup (value) {
      this.moveTarget = value
      this.moveTarget.inputCashAmt = ''
      this.openMoneyMovePopup = true
    },
    onCloseMoneyPopup (value) {
      this.moveTarget = null
      this.openMoneyMovePopup = false
    },
    parseFormattedValue (value) {
      return value.replace(/,/g, '')
    },
    getMyMemIds () {
      const params = {
        code: this.selectPartnerCode,
        masterCode: 'partner'
      }
      partnerMyMemIds(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.myMemIdsList = result.data.memIds
        }
      })
    },
    getPartnerList (page) {
      this.emitter.emit('Loading', true)
      if (!page) {
        page = this.pageInfo.page
      }
      const params = {
        searchMemId: this.searchMemId,
        page: page,
        partnerLevel: this.searchPartnerLevel,
        searchType: this.searchType,
        count_per_list: 40
      }
      console.log('[REQ]getRetailMyMemList : ', params)
      getRetailMyMemList(params).then(res => {
        console.log('[RES]getRetailMyMemList : ', res.data)
        this.emitter.emit('Loading', false)
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.list)
          this.partnerList = result.data.list

          this.partnerList.forEach(item => {
            item.inputCashAmt = ''
          })

          if (result.data.pageInfo) {
            this.pageInfo = result.data.pageInfo
          }
        }
      })
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log('partnerLevel : ', result.data)
          this.partnerLevelList = result.data.list
          this.newPartnerLevel = result.data.list[0]

          const partnerObj = {}
          for (let i = 0; i < this.partnerLevelList.length; i++) {
            const item = this.partnerLevelList[i]
            const code = item.code
            const codeName = item.codeName

            if (!partnerObj[code]) {
              partnerObj[code] = codeName
            }
          }

          this.partnerLevelObject = partnerObj
        }
      })
    },
    async memCash (type, item) {
      const memId = item.memId
      const cashStr = item.inputCashAmt
      console.log(item)
      if (!cashStr) {
        await this.onAlert('warningart', 'front.give.moneyInput')
        return false
      }

      if (!memId) {
        await this.onAlert('warningart', '회원 아이디를 선택해주세요.')
        return false
      }

      if (!this.isProcessing) {
        const cash = Number(cashStr.replace(/,/g, ''))
        this.isProcessing = true

        const params = {
          memId: memId
        }
        if (type === '-3') {
          params.inAmt = cash
        } else {
          params.outAmt = cash
        }

        const message = this.$t('front.cash.moneyMoveConfirm', { memNick: memId, cashAmt: cashStr, type: type === '-3' ? '지급' : '회수' })

        const confirm = await this.onConfirm(message)
        if (confirm) {
          this.emitter.emit('Loading', true)
          memCashInOut(params, type).then(async response => {
            const result = response.data
            console.log(result)
            this.emitter.emit('Loading', false)
            if (result.resultCode === '0') {
              if (type === '-3') {
                await this.onCheck('지급 완료')
              } else {
                await this.onCheck('회수 완료')
              }
              item.inputCashAmt = ''
              this.onCloseMoneyPopup(this.moveTarget)
              await this.getLoginMember()
            } else {
              await this.onAlert('warningart', result.resultMessage)
            }
            this.isProcessing = false
          })
        } else {
          this.isProcessing = false
        }
      }
    },
    updateCashAmt (item, value) {
      const parts = item.inputCashAmt.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      if (v === '0' || v === '') {
        item.inputCashAmt = ''
      } else {
        item.inputCashAmt = n
      }
    },
    setMoney (item, value) {
      const currentMoney = Number(item.inputCashAmt.replace(/,/g, ''))
      console.log(currentMoney)
      if (value === 0) {
        item.inputCashAmt = (value).toString()
      } else {
        item.inputCashAmt = (currentMoney + value).toString()
      }
      this.updateCashAmt(item)
    }
  }
}
</script>
<style scoped src="@/styles/striNew.css"></style>
<style scoped>
.text-break {overflow-wrap: break-word;word-break: break-all;white-space: normal;}
#PTpage .tab-content input {height: 22px; min-width:123px; border: solid 1px #dadde2; border-radius: 5px; box-sizing: border-box; line-height: 22px; font-size: 11px;}
@media (max-width: 500px) {
  .searchPTwrap {width: 100%;}
  .searchPT {flex-wrap: nowrap;}
  .datesearchPT {display: flex;}
  .PTsch {justify-content: right;}
}
</style>
